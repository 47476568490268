<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2021-03-05 13:51:42
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\views\outParkManage\equipmentManage\roadOutEquipmentRoadside\roadOutEquipmentDetailRoaddside.vue
-->
<!-- 路外设备详情 -->
<template>
  <el-drawer size="1048px" :title="$t('pop_up.Equipment_Management_Details')" :visible.sync="showFlag">
    <div class="draw-detail">
      <template v-if="equipmentList.length !== 0">
        <div v-for="item in equipmentList" :key="item.index">
          <equipment-list :dataSource="item"></equipment-list>
        </div>
      </template>
      <div class="empty" v-if="equipmentList.length === 0">{{ $t('pop_up.No_devices_currently_available') }}</div>
    </div>
  </el-drawer>
</template>
<script>
import equipmentList from "./equipmentList";
export default {
  name: "roadOutEquipmentDetailRoaddside",
  data() {
    return {
      equipmentList: {},
      showFlag: false,
    };
  },
  components: {
    equipmentList,
  },
  methods: {
    getDetails(parkId) {
      this.showFlag = true;
      Promise.all([
        this.$axios.get("/acb/2.0/bacb/thirdEquipment/" + parkId),
        this.$axios.get("/acb/2.0/bacb/getEquipmentList/" + parkId),
      ])
        .then((res) => {
          this.equipmentList = [...res[0].value, ...res[1].value] || [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.draw-detail
  height 100%
  background #f7f9fd
  padding 20px
  .empty
    text-align center
    margin-top 50vh
</style>
