<!-- 设备列表组价 -->
<template>
  <div class="main_box">
    <div class="title">{{ dataSource.thirdEquipmentType | equipmentTypeName }}</div>
    <div class="equipmentLists">
      <div
        class="equipmentList"
        :style="{ background: bgColor[dataSource.thirdEquipmentType] }"
        v-for="(item, index) in dataSource.thirdEquipmentList"
        :key="index"
      >
        <div class="equipmentDetail">
          <p
            class="status"
            :class="!item.equipmentState ? 'red' : item.equipmentState == 1 ? 'green' : 'blue'"
          >
            <i class="circle"></i> {{ item.equipmentState | equipmentStatus }}
          </p>
          <p class="name">{{ item.equipmentName }}</p>
          <p class="time">{{ item.updatedTime }}</p>
          <p class="time">{{ item.ipAddress }}</p>
        </div>
        <div>
          <img
            width="48px"
            height="50px"
            :src="dataSource.thirdEquipmentType | equipmentImage"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  data() {
    return {
      bgColor: {
        1: "#c2d6d6",
        2: "#cceeff",
        3: "#9fdf9f",
        4: "#FFF0ED",
        5: "#EBF1FF",
        6: "#ffefcc",
        7: "#ffefcc",
        8: "#a3c2c2",
        9: "#EBF1FF",
        10: "#9fdf9f",
        13: "#ffefcc",
        14: "#ffefcc",
        15: "#EAFAF9",
        16: "#EAFAF9",
      }
    };
  },
  props: {
    dataSource: {
      type: Object,
    },
  },
  filters: {
    equipmentTypeName(type) {
      let equipmentTypeList = {
        1: "MS",
        2: i18n.t('detail.Gate_machine'),
        3: i18n.t('detail.LED_screen'),
        4: i18n.t('detail.Identification_all_in_one_machine'),
        5: i18n.t('detail.IP_intercom'),
        6: i18n.t('detail.LED_payment_screen'),
        7: i18n.t('detail.QR_code_scanner'),
        8: i18n.t('detail.Residual_screen'),
        9: i18n.t('detail.ETC_antenna'),
        10: i18n.t('detail.Integrated_barrier_gate_machine'),
        13: i18n.t('detail.Intelligent_control_board'),
        14: i18n.t('detail.Lane_monitoring'),
        15: i18n.t('detail.Gate_controller'),
        16: i18n.t('detail.Data_collection_equipment'),
      };
      return equipmentTypeList[type];
    },
    equipmentStatus(status) {
      let equipmentStatusList = {
        0: i18n.t('detail.off_line'),
        1: i18n.t('detail.on_line'),
        2: i18n.t('detail.unknown'),
      };
      return equipmentStatusList[status];
    },
    equipmentImage(imgId) {
      return require("./equipment-" + imgId + ".png");
    },
  },
  mounted() {},
};
</script>
<style lang="stylus" scoped>
.main_box {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  .title {
    height: 64px;
    line-height: 64px;
    padding: 0px 20px;
    font-size: 16px;
    color: #1d2129;
  }

  .equipmentLists {
    padding: 0 20px;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
    overflow: hidden;
    flex-wrap: wrap;
    border-radius: 0 0 4px 4px;

    .equipmentList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      background-color: #ffffff;
      border-radius: 20px;
      margin-bottom: 20px;
      margin-right: 20px;

      .equipmentDetail {
        margin-right: 10px;

        .name {
          color: #333333;
          font-size: 16px;
        }

        .status {
          font-size: 12px;
          .circle {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        .time {
          color: #666666;
          font-size: 12px;
        }
        .green {
          color: #09D335;
          .circle {
            background: #09D335;
          }
        }
        .red {
          color: #F42500;
          .circle {
            background: #F42500;
          }
        }
        .blue {
          color: blue;
          .circle {
            background: blue;
          }
        }
        p {
          line-height: 26px;
        }
      }
    }
  }
}
</style>
