var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        size: "1048px",
        title: _vm.$t("pop_up.Equipment_Management_Details"),
        visible: _vm.showFlag,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showFlag = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "draw-detail" },
        [
          _vm.equipmentList.length !== 0
            ? _vm._l(_vm.equipmentList, function (item) {
                return _c(
                  "div",
                  { key: item.index },
                  [_c("equipment-list", { attrs: { dataSource: item } })],
                  1
                )
              })
            : _vm._e(),
          _vm.equipmentList.length === 0
            ? _c("div", { staticClass: "empty" }, [
                _vm._v(_vm._s(_vm.$t("pop_up.No_devices_currently_available"))),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }